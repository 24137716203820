var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"frm_memberheadline"}},[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":""},on:{"click":_vm.AddNew}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-plus")]),_vm._v(" เพิ่มหมู่บ้าน ")],1)]}}]),model:{value:(_vm.dialog_frm_memberheadline),callback:function ($$v) {_vm.dialog_frm_memberheadline=$$v},expression:"dialog_frm_memberheadline"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitData($event)}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("ข้อมูลหมู่บ้าน")])]),_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"items":this.$store.getters.getlistProvince || [],"item-value":"ProID","item-text":"ProName","label":"จังหวัด","required":""},model:{value:(_vm.dataFrm.mem_province),callback:function ($$v) {_vm.$set(_vm.dataFrm, "mem_province", $$v)},expression:"dataFrm.mem_province"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"items":this.$store.getters.getlistDistrict({
                        ProID: this.dataFrm.mem_province,
                      }) || [],"item-text":"DisName","item-value":"DisID","label":"อำเภอ","required":""},model:{value:(_vm.dataFrm.mem_district),callback:function ($$v) {_vm.$set(_vm.dataFrm, "mem_district", $$v)},expression:"dataFrm.mem_district"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"items":this.$store.getters.getlistLocality({
                        ProID: this.dataFrm.mem_province,
                        DisID: this.dataFrm.mem_district,
                      }) || [],"item-text":"LocName","item-value":"LocID","label":"ตำบล","required":""},model:{value:(_vm.dataFrm.mem_locality),callback:function ($$v) {_vm.$set(_vm.dataFrm, "mem_locality", $$v)},expression:"dataFrm.mem_locality"}})],1),_c('v-flex',{attrs:{"lg8":"","md12":""}},[_c('v-text-field',{attrs:{"label":"ชื่อหมู่บ้าน","required":""},model:{value:(_vm.dataFrm.vil_name),callback:function ($$v) {_vm.$set(_vm.dataFrm, "vil_name", $$v)},expression:"dataFrm.vil_name"}})],1),_c('v-flex',{attrs:{"lg4":"","md12":""}},[_c('v-text-field',{attrs:{"label":"หมู่ที่","required":""},model:{value:(_vm.dataFrm.vil_moo),callback:function ($$v) {_vm.$set(_vm.dataFrm, "vil_moo", $$v)},expression:"dataFrm.vil_moo"}})],1),_c('v-flex',{attrs:{"lg8":"","md12":""}},[_c('v-text-field',{attrs:{"label":"ผู้รับผิดชอบ","required":""},model:{value:(_vm.dataFrm.mem_responsible),callback:function ($$v) {_vm.$set(_vm.dataFrm, "mem_responsible", $$v)},expression:"dataFrm.mem_responsible"}})],1),_c('v-flex',{attrs:{"lg4":"","md12":""}},[_c('v-text-field',{attrs:{"label":"เบอร์โทร","required":""},model:{value:(_vm.dataFrm.mem_responsible_tel),callback:function ($$v) {_vm.$set(_vm.dataFrm, "mem_responsible_tel", $$v)},expression:"dataFrm.mem_responsible_tel"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","type":"submit"}},[_vm._v("บันทึก")]),_c('v-btn',{on:{"click":_vm.close_frm_memberheadline}},[_vm._v("ปิดหน้านี้")])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }