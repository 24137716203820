var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container--fluid pt-3"},[_c('v-card',[_c('v-card-title',[_vm._v(" ข้อมูลหมู่บ้าน "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('Frm_memberheadline',{attrs:{"dialog_frm_memberheadline":_vm.frm_memberheadline_dialog,"dataFrm":_vm.frm_memberheadline_dataFrm,"statAC":_vm.statAC},on:{"callback_frm_memberheadline":_vm.callback_frm_memberheadline1,"AddNew":_vm.AddNew}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-title',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"items":this.$store.getters.getlistProvince || [],"item-value":"ProID","item-text":"ProName","label":"จังหวัด","required":""},model:{value:(_vm.dataFrm.mem_province),callback:function ($$v) {_vm.$set(_vm.dataFrm, "mem_province", $$v)},expression:"dataFrm.mem_province"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"items":this.$store.getters.getlistDistrict({
                  ProID: this.dataFrm.mem_province,
                }) || [],"item-text":"DisName","item-value":"DisID","label":"อำเภอ","required":""},model:{value:(_vm.dataFrm.mem_district),callback:function ($$v) {_vm.$set(_vm.dataFrm, "mem_district", $$v)},expression:"dataFrm.mem_district"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"items":this.$store.getters.getlistLocality({
                  ProID: this.dataFrm.mem_province,
                  DisID: this.dataFrm.mem_district,
                }) || [],"item-text":"LocName","item-value":"LocID","label":"ตำบล","required":""},model:{value:(_vm.dataFrm.mem_locality),callback:function ($$v) {_vm.$set(_vm.dataFrm, "mem_locality", $$v)},expression:"dataFrm.mem_locality"}})],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":this.$store.getters.getlistVillate({
          ProID: this.dataFrm.mem_province,
          DisID: this.dataFrm.mem_district,
          LocID: this.dataFrm.mem_locality,
        }),"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cogs")]),_vm._v(" จัดการข้อมูล "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.EditItem(item)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v(" แก้ไขข้อมูล")],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }