<template>
  <div class="container--fluid pt-3">
    <v-card>
      <v-card-title>
        ข้อมูลหมู่บ้าน
        <v-divider class="mx-4" inset vertical></v-divider>
        <Frm_memberheadline
          :dialog_frm_memberheadline="frm_memberheadline_dialog"
          :dataFrm="frm_memberheadline_dataFrm"
          :statAC="statAC"
          @callback_frm_memberheadline="callback_frm_memberheadline1"
          @AddNew="AddNew"
        ></Frm_memberheadline>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-title>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-col cols="12" lg="4">
              <v-select
                v-model="dataFrm.mem_province"
                :items="this.$store.getters.getlistProvince || []"
                item-value="ProID"
                item-text="ProName"
                label="จังหวัด"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" lg="4">
              <v-select
                v-model="dataFrm.mem_district"
                :items="
                  this.$store.getters.getlistDistrict({
                    ProID: this.dataFrm.mem_province,
                  }) || []
                "
                item-text="DisName"
                item-value="DisID"
                label="อำเภอ"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" lg="4"
              ><v-select
                v-model="dataFrm.mem_locality"
                :items="
                  this.$store.getters.getlistLocality({
                    ProID: this.dataFrm.mem_province,
                    DisID: this.dataFrm.mem_district,
                  }) || []
                "
                item-text="LocName"
                item-value="LocID"
                label="ตำบล"
                required
              ></v-select>
            </v-col>
          </v-layout>
        </v-container>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="
          this.$store.getters.getlistVillate({
            ProID: this.dataFrm.mem_province,
            DisID: this.dataFrm.mem_district,
            LocID: this.dataFrm.mem_locality,
          })
        "
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on"
                ><v-icon>mdi-cogs</v-icon> จัดการข้อมูล
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click="EditItem(item)">
                <v-list-item-title
                  ><v-icon>mdi-pencil</v-icon> แก้ไขข้อมูล</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import Frm_memberheadline from "../components/frm_member_headline";
export default {
  components: { Frm_memberheadline },
  name: "member",
  data() {
    return {
      search: "",
      dataFrm: {
        mem_province: "50",
        mem_district: "10",
        mem_locality: "01",
      },
      headers: [
        { text: "จังหวัด", value: "ProName" },
        { text: "อำเภอ", value: "DisName" },
        { text: "ตำบล", value: "LocName" },
        { text: "ชื่อหมู่บ้าน", value: "vil_name" },
        { text: "หมู่ที่", value: "vil_moo" },
        { text: "ผู้รับผิดชอบ", value: "mem_responsible" },
        { text: "เบอร์โทร", value: "mem_responsible_tel" },
        { text: "จัดการ", value: "actions" },
      ],
      frm_memberheadline_dialog: false,
      frm_memberheadline_dataFrm: {},
      statAC: "",
    };
  },
  async mounted() {
    this.$store.dispatch("getlistVillate");
  },
  methods: {
    callback_frm_memberheadline1() {
      this.clear();
    },
    clear() {
      this.frm_memberheadline_dialog = false;
      this.frm_memberheadline_dataFrm = {};
    },
    AddNew() {
      this.frm_memberheadline_dialog = true;
      this.statAC = "AddNew";
      this.frm_memberheadline_dataFrm = {
        mem_province: "50",
        mem_district: "10",
        mem_locality: "01",
      };
    },
    EditItem(item) {
      this.frm_memberheadline_dialog = true;
      this.statAC = "Edit";
      let itemEdit = {};
      Object.assign(itemEdit, item, {
        mem_province: this.dataFrm.mem_province,
        mem_district: this.dataFrm.mem_district,
        mem_locality: this.dataFrm.mem_locality,
      });
      this.frm_memberheadline_dataFrm = itemEdit;
    },
  },
};
</script>

<style></style>
