<template>
  <div id="frm_memberheadline">
    <v-layout row justify-center>
      <v-dialog v-model="dialog_frm_memberheadline" persistent max-width="800px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark @click="AddNew" v-on="on"
            ><v-icon class="mr-2">mdi-account-plus</v-icon>
            เพิ่มหมู่บ้าน
          </v-btn>
        </template>
        <form @submit.prevent="submitData">
          <v-card>
            <v-card-title>
              <span class="headline">ข้อมูลหมู่บ้าน</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-col cols="12" lg="4">
                    <v-select
                      v-model="dataFrm.mem_province"
                      :items="this.$store.getters.getlistProvince || []"
                      item-value="ProID"
                      item-text="ProName"
                      label="จังหวัด"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <v-select
                      v-model="dataFrm.mem_district"
                      :items="
                        this.$store.getters.getlistDistrict({
                          ProID: this.dataFrm.mem_province,
                        }) || []
                      "
                      item-text="DisName"
                      item-value="DisID"
                      label="อำเภอ"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" lg="4"
                    ><v-select
                      v-model="dataFrm.mem_locality"
                      :items="
                        this.$store.getters.getlistLocality({
                          ProID: this.dataFrm.mem_province,
                          DisID: this.dataFrm.mem_district,
                        }) || []
                      "
                      item-text="LocName"
                      item-value="LocID"
                      label="ตำบล"
                      required
                    ></v-select>
                  </v-col>
                  <v-flex lg8 md12>
                    <v-text-field
                      label="ชื่อหมู่บ้าน"
                      v-model="dataFrm.vil_name"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex lg4 md12>
                    <v-text-field
                      label="หมู่ที่"
                      v-model="dataFrm.vil_moo"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex lg8 md12>
                    <v-text-field
                      label="ผู้รับผิดชอบ"
                      v-model="dataFrm.mem_responsible"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex lg4 md12>
                    <v-text-field
                      label="เบอร์โทร"
                      v-model="dataFrm.mem_responsible_tel"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" type="submit">บันทึก</v-btn>
              <v-btn @click="close_frm_memberheadline">ปิดหน้านี้</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "frm_memberheadline",
  data() {
    return {
      value: String,
    };
  },
  props: {
    dialog_frm_memberheadline: Boolean,
    dataFrm: {
      mem_province: { type: String, default: "50" },
      mem_district: { type: String, default: "10" },
      mem_locality: { type: String, default: "01" },
      vil_id: String,
      vil_moo: Number,
      vil_name: String,
      mem_responsible: String,
      mem_responsible_tel: String,
    },
    statAC: String,
  },
  methods: {
    clearData() {
      this.dialog_frm_memberheadline = false;
      this.dataFrm = {
        mem_province: "50",
        mem_district: "10",
        mem_locality: "01",
        vil_id: "",
        vil_moo: null,
        vil_name: "",
        mem_responsible: "",
        mem_responsible_tel: "",
      };
    },
    AddNew() {
      this.$emit("AddNew");
    },
    close_frm_memberheadline() {
      this.clearData();
      this.$emit("callback_frm_memberheadline");
    },
    submitData() {
      let vData = {};
      Object.assign(vData, this.dataFrm, { statAC: this.statAC });
      window.$.post(
        this.$store.state.pathAPI + "villate/updateData",
        vData,
        async (param) => {
          await window.Swal.fire({
            title: param.title,
            html: param.html,
            icon: param.icon,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
          });
          await this.$store.dispatch("getlistVillate");
          await this.close_frm_memberheadline();
        },
        "json"
      );
    },
  },
};
</script>

<style></style>
